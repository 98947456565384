import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'

import zhcn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/style/reset.css'

createApp(App).use(store).use(router).use(ElementPlus,{
    locale:zhcn
}).mount('#app')

//npm install vue3-websocket
// import socket from 'vue3-websocket'
// createApp(App).use(store).use(router).use(ElementPlus,{
//     locale:zhcn
// }).use(socket, 'ws://162.14.76.189:8090').mount('#app')

import { createRouter, createWebHashHistory ,createWebHistory} from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: "/Login"
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../layout/index.vue'),
    beforeEnter: (to, from, next) => {
      if( localStorage.getItem('userToken')){
        next()
      }else{
        next('/')
      }
    },
    children: [
      // {
      //   // 导入excel
      //   path: '/excelList',
      //   name: 'excelList',
      //   component: () => import('../views/financial/excelList.vue'),
      //   beforeEnter: (to, from, next) => {
      //     if(localStorage.getItem('userToken')){
      //       next()
      //     }else{
      //       next('/')
      //     }
      //
      //   },
      //   meta: {
      //     title: "excel",
      //     need_token: true,
      //     permission: ["1", "2", "3", "4", "5"]
      //   }
      // },
      {
        // home主页
        path: '/receiptList',
        name: 'receiptList',
        component: () => import('../views/financial/receiptList.vue'),
        beforeEnter: (to, from, next) => {
          if(localStorage.getItem('userToken')){
            next()
          }else{
            next('/')
          }
          
        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
      {
        // orderList 订单列表
        path: '/orderList',
        name: 'orderList',
        component: () => import('../views/financial/orderList.vue'),
        beforeEnter: (to, from, next) => {
          if(localStorage.getItem('userToken')){
            next()
          }else{
            next('/')
            // next()
          }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
